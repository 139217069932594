import { gql } from 'apollo-boost'

const RULE_SUMMARY = `
  id,
  name,
  description,
  enabled,
  priority,
  continueOn,
  conditions {
    type,
    config
  },
  actions {
    type,
    config
  }
`

export const ALL_RULES = gql`
  query AllRules($channelId: ID!, $input: StreamFilterInput) {
    allRules(channelId: $channelId, input: $input) {
      cursor,
      items {
        ${RULE_SUMMARY}
      }
    }
  }
`

export const GET_RULE = gql`
  query GetRule($id: ID!) {
    getRule(id: $id) {
      ${RULE_SUMMARY}
    }
  }
`

export const ADD_RULE = gql`
  mutation AddRule($channelId: ID!, $input: AddRuleInput!) {
    addRule(channelId: $channelId, input: $input) {
      ${RULE_SUMMARY}
    }
  }
`

export const UPDATE_RULE = gql`
  mutation UpdateRule($id: ID!, $input: UpdateRuleInput!) {
    updateRule(id: $id, input: $input) {
      ${RULE_SUMMARY}
    }
  }
`

export const REMOVE_RULE = gql`
  mutation RemoveRule($id: ID!) {
    removeRule(id: $id)
  }
`
