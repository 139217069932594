
import EnsureUser     from '~/components/mixins/user/ensure-user.coffee'
import EnsureVerified from '~/components/mixins/user/ensure-verified.coffee'
import EnsureContext  from '~/components/mixins/org/ensure-context.coffee'
import EnsureReady    from '~/components/mixins/org/ensure-ready.coffee'
import EnsureSetup    from '~/components/mixins/org/ensure-setup.coffee'
import notifications  from '~/components/notifications/notifications'

export default
  middleware: ['tokenContext', 'signedIn']
  mixins    : [EnsureUser, EnsureVerified, EnsureContext, EnsureReady, EnsureSetup]
  components: {notifications}

  computed:
    ready: ->
      @userReady and @userVerified and @orgSignedIn and @orgReady and @orgSetup
