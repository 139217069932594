# This mixin provides functionality to sign the user into the specified org
# 
# If the user is unable to signIn to the specified org for lack of permissions
# then they will be redirected to their home page
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { GET_ORG, SIGN_IN_ORG } from '~/queries/org'

export default
  data: ->
    orgSignedIn: false
  
  computed: {
    mapGetters('session', ['orgId'])...
  }
  
  created: ->
    orgId = @$route.params.orgId
    
    unless orgId
      @$replaceRoute '/'
    
    if @orgId == orgId
      @orgSignedIn = true
    else
      @signInOrg orgId
  
  methods: {
    mapMutations('session', ['setContext', 'setOrgToken'])...
    mapActions('notifications', ['apiError'])...
    
    signInOrg: (orgId) ->
      @$apollo.mutate
        mutation: SIGN_IN_ORG
        variables:
          input:
            id: orgId
      
      .then ({ data }) =>
        @setOrgToken data.signInOrg.token
        @setContext 'org'
        @orgSignedIn = true
      
      .catch (error) =>
        @apiError error
        @$replaceRoute '/'
  }
    
    
    
