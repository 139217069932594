import { SHA1 } from 'jshashes'

export default (_, inject) ->
  # Simple helper function to create consistent hashing partitions
  inject 'partition', (key, partitions) ->
    hash = (key) ->
      new SHA1().hex(key)
    
    digest = (key) ->
      index = hash "#{key}"
    
      index.split('').map (char) ->
        char.charCodeAt 0
    
    hashValueHash = (a, b, c, d) ->
      ((a << 24) | (b << 16) | (c << 8) | d) >>> 0
    
    hashValue = (key) ->
      x = digest key
    
      hashValueHash x[3], x[2], x[1], x[0]
    
    hashValue(key) % partitions
