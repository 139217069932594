# This mixin provides functionality ensure the org is setup
# 
# if the org is not setup, we will redirect to setup
import { mapGetters } from 'vuex'
import { GET_ORG } from '~/queries/org'

export default
  apollo:
    org:
      query: GET_ORG
      update: (data) -> data.getOrg
      skip: ->
        !@isOrg
      
  computed: {
    mapGetters('session', ['isOrg'])...
    
    isSetupRoute: ->
      @$route.path.match /\/setup$/
    
    orgLoaded: ->
      @org != undefined
      
    orgSetup: ->
      return true if @isSetupRoute
      
      @orgLoaded && @org?.status == 'active'
  }
  
  create: ->
    @redirectToSetup()
    
  watch:
    orgLoaded: ->
      @redirectToSetup()
      
  methods:
    redirectToSetup: ->
      return unless @orgLoaded
      
      return if @$route.path.match /\/setup$/
      
      return unless @org?.status
      
      if @org.status != 'active'
        @$replaceRoute "/orgs/#{@$route.params.orgId}/setup"
      
      
