














import AdminNav         from '@taka/ui-kit/components/navs/admin/admin-nav'
import AutomationList   from '@taka/ui-kit/components/navs/admin/automation-list'
import ChannelNav       from '@taka/ui-kit/components/navs/admin/channel-nav'
import ChannelsList     from '@taka/ui-kit/components/navs/admin/channels-list'
import ContactsList     from '@taka/ui-kit/components/navs/admin/contacts-list'
import ListsList        from '@taka/ui-kit/components/navs/admin/lists-list'
import FuturesList      from '@taka/ui-kit/components/navs/admin/futures-list'
import OrgNav           from '@taka/ui-kit/components/navs/admin/org-nav'
import _sortBy          from 'lodash.sortby'

export default

  data: ->
    matches: []
    navs : {
      admin          : { component: AdminNav                                                    }
      automations    : { component: AutomationList                                              }
      contacts       : { component: ContactsList                                                }
      channel        : { component: ChannelNav,    matchSpec: /channels\/.+\//, matchIndex: 5   }
      channels       : { component: ChannelsList                                                }
      lists          : { component: ListsList                                                   }
      orgs           : { component: AdminNav                                                    }
      futures        : { component: FuturesList                                                 }
      'org-settings' : { component: OrgNav                                                      }
    }

  watch:
    # Watch the route. search each new route for
    # the keys found in the `navs` array. For
    # each match, add a nav component to the DOM
    $route:
      immediate: true
      handler: (newRoute, prevRoute) ->
        haystack = newRoute.path.split "/"
        ar = []
        for key, val of @navs
          if val.matchSpec
            if newRoute.path.match val.matchSpec
              ar.push { component: val.component, index: val.matchIndex }
          else
            {doesMatch, matchIndex} = @doesMatch(haystack, key, val.avoid)
            if doesMatch
              ar.push { component: val.component, index: matchIndex }

        @matches = _sortBy ar, 'index'

  methods:
    doesMatch: (haystack, needleStr, avoidStr)->
      matchIndex = haystack.indexOf( needleStr )
      if matchIndex > -1
        # if `avoidStr` not provided OR no strings to avoid found in haystack
        if !avoidStr? or haystack.indexOf( avoidStr ) == -1
          return {doesMatch:true, matchIndex}
      {doesMatch: false}
