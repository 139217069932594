import reject from 'lodash.reject'

export const state = () => ({
  errors: [],
  messages: []
})

var count = 0;

export const mutations = {

  // ------------ Errors
  addError(state, message) {
    state.errors = [ ...state.errors, message ]
  },

  removeError(state, id) {
    state.errors = reject(state.errors, { id })
  },

  // clear all notifications
  resetErrors(state) {
    state.errors = []
  },

  // ------------ Messages
  addMsg(state, message) {
    state.messages = [ ...state.messages, message ]
  },

  removeMsg(state, id) {
    state.messages = reject(state.messages, { id })
  },

  // clear all notifications
  resetMessages(state) {
    state.messages = []
  }
}

export const actions = {

  // add an Error
  addError({ commit }, { type, code, message }) {
    commit('addError', { id:count++, type, code, message })
  },

  // add a Message
  addMessage({ commit, dispatch }, { type, code, message, timeout }) {
    let id = count++;
    commit('addMsg', { id, type, code, message })
    if(timeout){
      setTimeout( ()=>{
        dispatch('clearMessage', id)
      }
      , timeout * 1000 )
    }
  },

  // clear a notification
  clearMessage({ commit }, id) {
    commit('removeMsg', id)
  },

  clearError({ commit }, id) {
    commit('removeError', id)
  },

  // clear all notifications
  clearAll({ commit }) {
    commit('reset')
  },

  // handle API errors
  apiError({ dispatch }, { graphQLErrors, message }) {
    if (graphQLErrors && graphQLErrors.length > 0) {
      graphQLErrors.forEach(({ message }) => {
        dispatch('addError', { type: 'error', message })
      })
    } else {
      dispatch('addError', { type: 'error', message })
    }
  }
}
