# This mixin provides functionality to ensure the user is verified
# 
# If the user isn't verified, it will redirect them to a page where
# they will need to verify they own their phone number
import { GET_ME } from '~/queries/user'

export default
  apollo:
    user:
      query: GET_ME
      update: (data) -> data.getMe

  computed:
    userLoaded: ->
      @user != undefined
      
    userReady: ->
      @userLoaded and @user != null  
    
    userVerified: ->
      @userReady and @user.verified
  
  created: ->
    @ensureVerified()
      
  watch:
    userLoaded: ->
      @ensureVerified()
      
  methods:
    ensureVerified: ->
      if @userReady and not @userVerified
        @$replaceRoute '/verify'
    
