import { gql }             from 'apollo-boost'
import { PROCESS_SUMMARY } from '~/queries/process'

const MACRO_SUMMARY = `
  id,
  name,
  trigger,
  description,
  actions {
    type,
    config
  }
`

export const MY_MACROS = gql`
  query MyMacros($input: StreamFilterInput) {
    allMacros(input: $input) {
      cursor,
      items {
        ${MACRO_SUMMARY}
      }
    }
  }
`

export const ORG_MACROS = gql`
  query OrgMacros($orgId: ID!, $input: StreamFilterInput) {
    allMacros(orgId: $orgId, input: $input) {
      cursor,
      items {
        ${MACRO_SUMMARY}
      }
    }
  }
`

export const CHANNEL_MACROS = gql`
  query ChannelMacros($channelId: ID!, $input: StreamFilterInput) {
    allMacros(channelId: $channelId, input: $input) {
      cursor,
      items {
        ${MACRO_SUMMARY}
      }
    }
  }
`

export const GET_MACRO = gql`
  query GetMacro($id: ID!) {
    getMacro(id: $id) {
      ${MACRO_SUMMARY}
    }
  }
`

export const ADD_MACRO = gql`
  mutation AddMacro($orgId: ID, $channelId: ID, $input: AddMacroInput!) {
    addMacro(orgId: $orgId, channelId: $channelId, input: $input) {
      ${MACRO_SUMMARY}
    }
  }
`

export const UPDATE_MACRO = gql`
  mutation UpdateMacro($id: ID!, $input: UpdateMacroInput!) {
    updateMacro(id: $id, input: $input) {
      ${MACRO_SUMMARY}
    }
  }
`

export const REMOVE_MACRO = gql`
  mutation RemoveMacro($id: ID!) {
    removeMacro(id: $id)
  }
`

export const PROCESS_MACRO = gql`
  mutation ProcessMacro($id: ID!, $threadId: ID!) {
    processMacro(id: $id, threadId: $threadId) {
      ${PROCESS_SUMMARY}
    }
  }
`
