export default function ({ store, route }) {
  // extract the orgId from the params
  const orgId = route.params.orgId
  
  // if orgId is not in the params, we need to ensure the token context is user
  if (!orgId && store.getters['session/isOrg']) {
    store.commit('session/setContext', 'user')
  }
  
  // if orgId is in the params, let's check to see if it matches the orgId
  // in the current token. If not let's remove it and set the context to user.
  if (orgId && orgId != store.getters['session/orgId']) {
    store.commit('session/unsetOrgToken')
    store.commit('session/setContext', 'user')
  }
  
  // if orgId is in the params and matches the orgId in the current token
  // let's ensure the session is set to use that token
  if (orgId && orgId == store.getters['session/orgId']) {
    store.commit('session/setContext', 'org')
  }
  
  // todo: trigger token refresh logic
}
