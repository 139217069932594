import { render, staticRenderFns } from "./automation-nav.vue?vue&type=template&id=a69a0cee&scoped=true&lang=pug&"
import script from "./automation-nav.vue?vue&type=script&lang=coffee&"
export * from "./automation-nav.vue?vue&type=script&lang=coffee&"
import style0 from "./automation-nav.vue?vue&type=style&index=0&id=a69a0cee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a69a0cee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavItems: require('/vercel/path0/components/automation/nav-items.vue').default})
