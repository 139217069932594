# This mixin provides functionality ensure the org exists and is ready
# 
# if loading the org returns null we'll redirect back to the homepage
import { mapGetters, mapMutations } from 'vuex'
import { GET_ORG } from '~/queries/org'

export default
  apollo:
    org:
      query: GET_ORG
      update: (data) -> data.getOrg
      skip: ->
        !@isOrg
  
  computed: {
    mapGetters('session', ['isOrg'])...
    
    orgLoaded: ->
      @org != undefined
      
    orgReady: ->
      @orgLoaded && @org != null
  }
      
  watch:
    orgLoaded: ->
      @redirectIfNotReady()
      
  created: ->
    @redirectIfNotReady()
  
  methods: {
    mapMutations('session', ['setContext', 'unsetOrgToken'])...
    
    redirectIfNotReady: ->
      return unless @orgLoaded
      
      if not @orgReady
        @unsetOrgToken()
        @setContext 'user'
        @$replaceRoute '/'
  }
