import { onGlobalSetup, provide } from '@nuxtjs/composition-api'
import { Realtime }               from 'ably'

export default ({ $config }, inject) ->
  # Create a new realtime connection 
  realtime = new Realtime($config.ablyToken)
  
  # inject it into the app (classic API)
  inject 'realtime', realtime
  
  # inject it into the app (vue 3)
  onGlobalSetup ->
    provide 'realtime', realtime
