import { gql }           from 'apollo-boost'
import { WEEK_SCHEDULE } from '~/queries/schedule'

const ORG_SUMMARY = `
  id,
  name,
  role,
  status,
`

const ORG_DETAILS = `
  id,
  name,
  number,
  status,
  defaultChannels
  ${WEEK_SCHEDULE},
`

export const ALL_ORGS = gql`
  query AllOrgs($input: StreamFilterInput) {
    allOrgs(input: $input) {
      cursor,
      items {
        ${ORG_SUMMARY}
      }
    }
  }
`

export const GET_ORG = gql`
  query GetOrg {
    getOrg {
      ${ORG_DETAILS}
    }
  }
`

export const ADD_ORG = gql`
  mutation AddOrg($input: AddOrgInput!) {
    addOrg(input: $input) {
      ${ORG_DETAILS}
    }
  }
`

export const SETUP_ORG = gql`
  mutation SetupOrg($input: SetupOrgInput!) {
    setupOrg(input: $input) {
      ${ORG_DETAILS}
    }
  }
`

export const UPDATE_ORG = gql`
  mutation UpdateOrg($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      ${ORG_DETAILS}
    }
  }
`

export const RESET_ORG = gql`
  mutation ResetOrg {
    resetOrg
  }
`

export const REMOVE_ORG = gql`
  mutation RemoveOrg {
    removeOrg
  }
`

export const SIGN_IN_ORG = gql`
  mutation SignInOrg($input: SignInOrgInput!) {
    signInOrg(input: $input) {
      token
    }
  }
`
