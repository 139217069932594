
export default
  data: ->
    show: true

  created: ->
    setTimeout ->
      @show = true
    , 1000

