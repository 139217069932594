import jwtDecode from 'jwt-decode'

export const state = () => ({
  userToken: '',
  orgToken : '',
  context  : 'user'
})

export const getters = {
  token(state) {
    if (state.context == 'user')
      return state.userToken
    
    if (state.context == 'org')
      return state.orgToken
  },
  
  isAuthenticated(state, getters) {
    return getters.token != ''
  },
  
  userTokenData(state) {
    // exit early if we don't have a token
    if (state.userToken == '') {
      return {}
    }
    
    // decode the token
    return jwtDecode(state.userToken)
  },
  
  userId(state, getters) {
    const data = getters.userTokenData
    
    return data.userId
  },
  
  orgTokenData(state) {
    // exit early if we don't have a token
    if (state.orgToken == '') {
      return {}
    }
    
    // decode the token
    return jwtDecode(state.orgToken)
  },
  
  isOrg(state) {
    return state.context == 'org'
  },
  
  orgId(state, getters) {
    const data = getters.orgTokenData
    
    // return the orgId if it exists
    return data.orgId
  },

  role(state, getters) {
    const data = getters.orgTokenData

    // return the role if it exists
    return data.role
  }
}

export const mutations = {
  setUserToken(state, token) {
    state.userToken = token
  },
  
  setOrgToken(state, token) {
    state.orgToken = token
  },
  
  unsetUserToken(state) {
    state.userToken = ''
  },
  
  unsetOrgToken(state) {
    state.orgToken = ''
  },
  
  unsetTokens(state) {
    state.userToken = ''
    state.orgToken  = ''
    state.context   = 'user'
  },
  
  setContext(state, context) {
    state.context = context
  }
}
