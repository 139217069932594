# yarn add graphql graphql-tag @apollo/client vue-apollo @vue/apollo-composable
import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import { BatchHttpLink }               from '@apollo/client/link/batch-http'
import { onGlobalSetup, provide }      from '@nuxtjs/composition-api'
import { DefaultApolloClient }         from '@vue/apollo-composable'
import Vue                             from 'vue'
import VueApollo                       from 'vue-apollo'

Vue.use VueApollo

export default ({ app, $config, store }) ->
  # initialize a default client
  apolloClient = new ApolloClient
    # cache in memory, so a page refresh clears out the cache
    cache: new InMemoryCache
    
    # batch queries within the same request
    link: new BatchHttpLink 
      # fetch the URI from the env config
      uri : $config.apiURL
      # how long to wait before executing batch
      batchInterval: 10
      # set the auth token in the header if we have one
      fetch : (uri, options) ->
        # fetch the token from the store
        token = store.getters['session/token']

        if token != ""
          options.headers.Authorization = "Bearer #{token}"

        # execute the request
        fetch(uri, options)

  # initialize the apollo provider
  apolloProvider = new VueApollo 
    defaultClient: apolloClient
  
  # add the provider to the app
  app.apolloProvider = apolloProvider
  
  onGlobalSetup ->
    provide DefaultApolloClient, apolloClient
