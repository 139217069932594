# This mixin provides functionality to ensure the user is loaded and available
# 
# If the user is not available, we'll redirect to home
import { mapMutations } from 'vuex'
import { GET_ME } from '~/queries/user'

export default
  apollo:
    user:
      query: GET_ME
      update: (data) -> data.getMe
      
  computed:
    userLoaded: ->
      @user != undefined
    
    userReady: ->
      @userLoaded and @user != null
      
  created: ->
    @signOutUnlessReady()
    
  watch:
    userLoaded: ->
      @signOutUnlessReady()
      
  methods: {
    mapMutations('session', ['unsetTokens'])...
    
    signOutUnlessReady: ->
      return unless @userLoaded
      
      unless @userReady
        @unsetTokens()
        @$replaceRoute '/sign-in'
  }
    
