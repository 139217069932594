
import { mapState } from 'vuex'

export default
  computed:{
    ...mapState 'notifications', ['errors', 'messages']
  }
  methods:
    clearError: (id)->
      @$store.dispatch 'notifications/clearError', id

    clearMessage: (id)->
      @$store.dispatch 'notifications/clearMessage', id

