
# a few helper functions to interact with the router
export default (ctx, inject) ->
  
  # replace the current route
  inject 'replaceRoute', (path) ->
    { route } = ctx.app.router.resolve(path, ctx.route, false)
    ctx.app.router.replace route, (->)
  
  # push onto the current route
  inject 'pushRoute', (path) ->
    { route } = ctx.app.router.resolve(path, ctx.route, false)
    ctx.app.router.push route, (->)
  
  # set a querystring param
  inject 'setQueryParam', (key, val) ->
    query = { ctx.route.query... }
    
    # no sense re-setting the same value and bonking the router
    if query[key] == val
      return
    
    query[key] = val
    ctx.app.router.replace { query }
  
  # unset a querystring param
  inject 'unsetQueryParam', (key) ->
    query = { ctx.route.query... }
    
    # no sense removing a value that doesn't exist and bonking the router
    unless query[key]
      return
    
    delete query[key]
    ctx.app.router.replace { query }
    
  # reset all of the querystring params
  inject 'resetQueryParams', (query) ->
    ctx.app.router.replace { query }
