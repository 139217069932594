import { gql } from 'apollo-boost'

const USER_SUMMARY = `
  id,
  number,
  name,
  verified,
  online
`

// input: number, name, password
export const SIGN_UP = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      token
    }
  }
`

// input: number, password
export const SIGN_IN = gql`
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      token
    }
  }
`

export const GET_ME = gql`
  query GetMe {
    getMe {
      ${USER_SUMMARY}
    }
  }
`

export const SEND_VERIFICATION = gql`
  mutation SendVerification {
    sendVerification
  }
`

// input: code
export const VERIFY_NUMBER = gql`
  mutation VerifyNumber($input: VerifyInput!) {
    verifyNumber(input: $input)
  }
`

export const RENEW_TOKEN = gql`
  mutation RenewToken {
    renewToken {
      token
    }
  }
`

// input: oldPassword, newPassword
export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`

// input: number
export const RECOVER_PASSWORD = gql`
  mutation RecoverPassword($input: RecoverPasswordInput!) {
    recoverPassword(input: $input)
  }
`

// input: number, code, password
export const RESET_RECOVERED_PASSWORD = gql`
  mutation ResetRecoveredPassword($input: ResetRecoveredPasswordInput!) {
    resetRecoveredPassword(input: $input) {
      token
    }
  }
`

// input: name
export const UPDATE_USER_NAME = gql`
  mutation UpdateName($input: UpdateNameInput!) {
    updateName(input: $input) {
      ${USER_SUMMARY}
    }
  }
`

export const CLOCK_IN = gql`
  mutation ClockIn {
    clockIn {
      ${USER_SUMMARY}
    }
  }
`

export const CLOCK_OUT = gql`
  mutation ClockOut {
    clockOut {
      ${USER_SUMMARY}
    }
  }
`
