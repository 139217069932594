
import EnsureUser     from '~/components/mixins/user/ensure-user.coffee'
import EnsureVerified from '~/components/mixins/user/ensure-verified.coffee'
import notifications from '~/components/notifications/notifications'

export default
  middleware: ['tokenContext', 'signedIn']
  mixins    : [EnsureUser, EnsureVerified]
  components: {notifications}

  computed:
    ready: ->
      @userReady and @userVerified

