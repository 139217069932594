import { gql } from 'apollo-boost'

export const PROCESS_SUMMARY = `
  id,
  name,
  status,
  error,
  actions {
    type,
    config,
    status
  }
`

export const ALL_PROCESSES = gql`
  query AllProcesses($threadId: ID!, $input: StreamFilterInput) {
    allProcesses(threadId: $threadId, input: $input) {
      cursor,
      items {
        ${PROCESS_SUMMARY}
      }
    }
  }
`

export const GET_PROCESS = gql`
  query GetProcess($id: ID!) {
    getProcess(id: $id) {
      ${PROCESS_SUMMARY}
    }
  }
`

export const REMOVE_PROCESS = gql`
  mutation RemoveProcess($id: ID!) {
    removeProcess(id: $id)
  }
`
