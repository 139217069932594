
export const DAY_SCHEDULE = `
  status,
  from,
  to
`

export const WEEK_SCHEDULE = `
  timezone,
  schedule {
    mon {
      ${DAY_SCHEDULE}
    },
    tue {
      ${DAY_SCHEDULE}
    },
    wed {
      ${DAY_SCHEDULE}
    },
    thu {
      ${DAY_SCHEDULE}
    },
    fri {
      ${DAY_SCHEDULE}
    },
    sat {
      ${DAY_SCHEDULE}
    },
    sun {
      ${DAY_SCHEDULE}
    }
  }
`
