import { gql } from 'apollo-boost'

const CHANNEL_SUMMARY = `
  id,
  name,
  description,
  number
`

const CHANNEL_DETAILS = `
  voicemailText,
  voicemailAudio
`

const PARTICIPANT_SUMMARY = `
  id,
  name,
  observer,
  online
`

const AUDIO_UPLOAD_URL_SUMMARY = `
  fileName,
  presignedURL,
  fileURL
`

// ############# Channels #############

export const ALL_CHANNELS = gql`
  query AllChannels($input: StreamFilterInput) {
    allChannels(input: $input) {
      cursor,
      items {
        ${CHANNEL_SUMMARY}
      }
    }
  }
`

export const GET_CHANNEL = gql`
  query GetChannel($id: ID!) {
    getChannel(id: $id) {
      ${CHANNEL_SUMMARY}
    }
  }
`

export const GET_CHANNEL_DETAILS = gql`
  query GetChannelDetails($id: ID!) {
    getChannel(id: $id) {
      ${CHANNEL_SUMMARY}
      ${CHANNEL_DETAILS}
    }
  }
`

export const ADD_CHANNEL = gql`
  mutation AddChannel($input: AddChannelInput!) {
    addChannel(input: $input) {
      ${CHANNEL_SUMMARY}
    }
  }
`

export const UPDATE_CHANNEL = gql`
  mutation UpdateChannel($id: ID!, $input: UpdateChannelInput!) {
    updateChannel(id: $id, input: $input) {
      ${CHANNEL_SUMMARY}
    }
  }
`

export const UPDATE_CHANNEL_DETAILS = gql`
  mutation UpdateChannelDetails($id: ID!, $input: UpdateChannelInput!) {
    updateChannel(id: $id, input: $input) {
      ${CHANNEL_SUMMARY}
      ${CHANNEL_DETAILS}
    }
  }
`

export const REMOVE_CHANNEL = gql`
  mutation RemoveChannel($id: ID!) {
    removeChannel(id: $id)
  }
`

// ############# Participants #############

export const ALL_PARTICIPANTS = gql`
  query AllParticipants($channelId: ID!, $input: StreamFilterInput) {
    allChannelParticipants(channelId: $channelId, input: $input) {
      cursor,
      items {
        ${PARTICIPANT_SUMMARY}
      }
    }
  }
`

export const ALL_ONLINE_PARTICIPANTS = gql`
  query AllOnlineParticipants($channelId: ID!, $input: StreamFilterInput) {
    allOnlineChannelParticipants(channelId: $channelId, input: $input) {
      cursor,
      items {
        ${PARTICIPANT_SUMMARY}
      }
    }
  }
`

export const GET_PARTICIPANT = gql`
  query GetParticipant($channelId: ID!, $id: ID!) {
    getChannelParticipant(channelId: $channelId, id: $id) {
      ${PARTICIPANT_SUMMARY}
    }
  }
`

export const ADD_PARTICIPANT = gql`
  mutation AddParticipant($channelId: ID!, $input: AddChannelParticipant!) {
    addChannelParticipant(channelId: $channelId, input: $input) {
      ${PARTICIPANT_SUMMARY}
    }
  }
`

export const ADD_PARTICIPANTS = gql`
  mutation AddParticipants($channelId: ID!, $input: AddChannelParticipants!) {
    addChannelParticipants(channelId: $channelId, input: $input) {
      ${PARTICIPANT_SUMMARY}
    }
  }
`

export const UPDATE_PARTICIPANT = gql`
  mutation UpdateParticipant($channelId: ID!, $id: ID!, $input: UpdateChannelParticipantInput!) {
    updateChannelParticipant(channelId: $channelId, id: $id, input: $input) {
      ${PARTICIPANT_SUMMARY}
    }
  }
`

export const REMOVE_PARTICIPANT = gql`
  mutation RemoveParticipant($channelId: ID!, $id: ID!) {
    removeChannelParticipant(channelId: $channelId, id: $id)
  }
`

// ############# Audio Upload #############

export const GET_AUDIO_UPLOAD_URL = gql`
  query GetAudioUploadURL($channelId: ID!, $fileType: String!) {
    getAudioUploadURL(channelId: $channelId, fileType: $fileType) {
      ${AUDIO_UPLOAD_URL_SUMMARY}
    }
  }
`
