
import { mapGetters } from 'vuex'

export default
  props:
    kind  : { type: String, required: true }
    items : { type: Array, default: -> [] }

  computed: {
    mapGetters('session', ['orgId'])...

    channelId: ->
      @$route.params.channelId
  }

  methods:
    itemLink: (id) ->
      switch @kind
        when 'macros'
          "/orgs/#{@orgId}/macros/#{id}/edit"
        when 'rules'
          "/orgs/#{@orgId}/org-settings/channels/#{@channelId}/rules/#{id}/edit"
