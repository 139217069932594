import { extend, setInteractionMode, configure } from 'vee-validate';
import { required, digits } from 'vee-validate/dist/rules';

# Set the css classes that will be added to fields indicating their status
# https://logaretm.github.io/vee-validate/guide/state.html#css-classes
configure
  classes:
    valid   : 'is-valid',
    invalid : 'is-invalid',

# Don't validate after every key press unless field is already errored
setInteractionMode 'eager'

# ------------------------------------ Rules

# Required
extend 'required',
  {
    ...required,
    message: '{_field_} is required'
  }

# Digits
extend 'digits', digits

# Define valid phone number
extend 'phone',
  validate: (value) ->
    regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    regex.test value
